<template>
  <div>
    <SubHeader :type="$t('profile.title')" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarUser />
        </div>
        <TransitionFade>
          <div class="md:col-span-4 h-auto space-y-3" v-if="$store.state.Menu.isSettingsFormsOpen">
            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("profile.data") }}</div>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("projectProfile.name") }}</label>
                      <InputText v-on:keyup.enter="updateName" v-model="newValues.name" type="text" class="p-inputtext-sm" :placeholder="newValues.name" />
                    </div>
                    <div class="form-group">
                      <label for="email" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("profile.email") }}</label>
                      <InputText v-on:keyup.enter="updateName" v-model="profileSettings.email" type="email" name="email" id="email" class="p-inputtext-sm" :placeholder="newValues.name" />
                    </div>
                  </div>

                  <div>
                    <TransitionFade>
                      <Message v-if="differentName" severity="success">{{ $t("profile.newName") }}</Message>
                    </TransitionFade>
                    <Button :disabled="validateInputName" @click="updateName" class="p-button-rounded p-button-primary p-button-text" :label="$t('profile.btnSave')" />
                  </div>
                </div>
              </template>
            </Card>

            <Card>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="text-gray-700 block text-sm mb-3 font-semibold">{{ $t("profile.updatePass") }}</div>
                  <TransitionFade>
                    <Message v-if="differentPasswords" severity="error">{{ $t("profile.differentPass") }}</Message>
                  </TransitionFade>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4" v-if="!getUser.setPassword">
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("profile.currentPass") }}</label>
                      <InputText v-model="profileSettings.current" type="password" name="Current" id="Current" class="p-inputtext-sm" />
                    </div>
                  </div>

                  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="form-group">
                      <label for="Name" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("profile.newPass") }}</label>
                      <InputText v-model="profileSettings.new" type="password" name="New" id="New" class="p-inputtext-sm" />
                    </div>
                    <div class="form-group">
                      <label for="email" class="block text-gray-800 font-semibold text-sm mb-2">{{ $t("profile.confirm") }}</label>
                      <InputText v-model="profileSettings.confirm" type="password" name="Confirm" id="Confirm" class="p-inputtext-sm" />
                    </div>
                  </div>

                  <div>
                    <Button :disabled="validateInputConfirm" @click="updatePassword" class="p-button-rounded p-button-primary p-button-text" :label="$t('profile.btnSave')" />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import TransitionFade from "@/components/TransitionFade"
import SidebarUser from "@/components/SidebarUser"
import * as yup from "yup"
import axiosClient from "@/config/AxiosClient"

import Card from "primevue/card"
import Message from "primevue/message"
import Button from "primevue/button"
import InputText from "primevue/inputtext"

export default {
  name: "Profile",
  components: {
    SubHeader,
    TransitionFade,
    SidebarUser,

    Card,
    Message,
    Button,
    InputText
  },
  data: function () {
    const profileSchema = yup.object({
      Current: yup.string().required().min(8),
      New: yup.string().required().min(8),
      NewName: yup.string().required(),
      Confirm: yup.string().required().min(8)
    })
    return {
      TitleSection: "Personal data",
      show: false,
      profileSettings: {
        name: this.$store.state.Auth.user.name ? this.$store.state.Auth.user.name : null,
        email: this.$store.state.Auth.user.email ? this.$store.state.Auth.user.email : null,
        current: "",
        new: "",
        confirm: ""
      },
      newValues: {
        name: this.$store.state.Auth.user.name ? this.$store.state.Auth.user.name : null,
        email: this.$store.state.Auth.user.email ? this.$store.state.Auth.user.email : null
      },
      profileSchema,
      differentName: false,
      differentPasswords: false,
      error: false,
      errorMessage: ""
    }
  },
  created() {
    this.$store.dispatch("Menu/openSettingsForms")
  },
  mounted() {
    this.profileSettings.Name = this.$store.state.Auth.user.name
  },
  computed: {
    validateInputName() {
      return this.newValues.name == this.profileSettings.Name
    },
    // Currently not in use
    // validateInputEmail() {
    //     return this.profileSettings.email == this.$store.state.Auth.user.email
    // },
    validateInputConfirm() {
      return this.profileSettings.confirm === ""
    },
    getUser(){
      return this.$store.state.Auth.user
    }
  },
  methods: {
    handleSubmit(values) {
      this.updatePassword(values)
    },
    updatePassword: function () {
      if (this.profileSettings.new === this.profileSettings.confirm) {
        this.differentPasswords = false
        let payload = {
          new_password: this.profileSettings.new,
          new_password_confirmation: this.profileSettings.confirm
        }
        if(!this.getUser.setPassword){
          payload.current_password = this.profileSettings.current
        }
        this.$store.dispatch("Auth/updatePassword", payload).then((res) => {
          if (res.status == 500) {
            this.$toast.error(this.$t("utils.toast.error"))
          }

          if (res.status == 422) {
            Object.entries(res.data.errors).forEach((keyValue) => {
              keyValue[1].forEach(message => {
                this.$toast.error(message)
              })
            });
            this.error = true
          }

          if (res.status == 204) {
            this.profileSettings.current = ""
            this.profileSettings.new = ""
            this.profileSettings.confirm = ""
            this.$store.dispatch('Auth/getUser')
            this.$toast.success(this.$t("utils.toast.passwordChanged"))
          }
        })
      } else {
        this.differentPasswords = true
      }
    },
    async updateName() {
      let response = await axiosClient.put(`api/2.0/auth/profile`, this.newValues)
      if (this.profileSettings.name !== this.newValues.name) {
        if (response.status == 500) {
          this.$toast.error(this.$t("utils.toast.error"))
        }
        if (response.status == 422) {
          this.errorMessage = Object.keys(response.data.errors)
            .map(function (errorKey) {
              return response.data.errors[errorKey].join(",")
            })
            .join(",")
          this.error = true
        }
        if (response.status == 204) {
          this.differentName = true
        }
      }
    }
  }
}
</script>
